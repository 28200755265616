import * as React from 'react';
import Modal from 'react-modal';
import { Stream } from '@cloudflare/stream-react';

interface StreamModalParams {
  isOpen: boolean;
  streamId: string;
  closeModal: () => void;
}

export const StreamModal: React.FC<StreamModalParams> = props => {
  const [streamLoaded, setStreamLoaded] = React.useState(false);

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={props.isOpen}
      className="w-80 w-50-l relative tc flex items-center justify-center flex-column"
      overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg--grayOpaque flex items-center justify-center z-max"
      contentLabel="Video Player"
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
    >
      <div className="w-100">
        <Stream
          src={props.streamId}
          controls
          preload="auto"
          autoplay={true}
          onLoadStart={() => {
            setStreamLoaded(true);
          }}
        />
      </div>

      <button
        className="modal-close-button absolute f6 gray5 pointer p1 bn bg--transparent"
        onClick={() => {
          props.closeModal();
          setStreamLoaded(false);
        }}
      >
        <svg
          style={{ width: '32px', height: '32px', fill: '#ffffff' }}
          aria-label=""
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
        >
          <path d="M14.3 3.625L12.375 1.7 8 6.075 3.625 1.7 1.7 3.625 6.075 8 1.7 12.375 3.625 14.3 8 9.925l4.375 4.375 1.925-1.925L9.925 8 14.3 3.625z"></path>
        </svg>
      </button>
    </Modal>
  );
};
